import React from 'react';
import { Container } from './BannersSection.styled';
import Card2, { Card2Props } from '@/componentsNew/blocks/Cards/Card2/Card2';
import { useRouter } from 'next/router';
import { METRIKS_SAMPLES } from '@/metriks/metriksSample';
import { sendMetrik } from '@/helpers';

export interface IBannerSection {
    banners: Card2Props[];
}
const BannersSection = ({ banners }: IBannerSection) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    const getMetriksSample = (index: number) => {
        if (!METRIKS_SAMPLES[pathname]?.hasOwnProperty(`banner${index}`)) return;

        return {
            event: METRIKS_SAMPLES[pathname][`banner${index}`].event,
            category: METRIKS_SAMPLES[pathname][`banner${index}`].category,
            action: {}
        };
    };

    return (
        <Container>
            {banners.map((banner, i) => (
                <Card2
                    key={i}
                    {...banner}
                    clickOnWrapper={() => {
                        const metriksSample = getMetriksSample(i);
                        if (!metriksSample) return;
                        sendMetrik(metriksSample.event, metriksSample.category, {});
                    }}
                />
            ))}
        </Container>
    );
};

export default BannersSection;
