import React from 'react';
import Link from 'next/link';
import { Container, Wrapper, Title, Text, Bg, CardAngle, BgWrapper } from './Card2.styled';
import { BaseOffsetsUI, BaseUI } from '@/interfaces';
import { Colors } from '@/style/colors';
import Angle from '@/icons/cards/new/angle.svg';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';

export interface Card2Props extends BaseUI, BaseOffsetsUI {
    title?: string;
    text?: string;
    image?: string;
    href?: string;
    bgColor?: Colors;
    textColor?: Colors;
    angles?: string[];
    modalId?: string;
    clickOnWrapper?: React.MouseEventHandler;
}

const Card2: React.FC<Card2Props> = ({ className, title, text, image, href, bgColor, textColor, angles, modalId, clickOnWrapper }) => {
    const Card = (
        <Container className={className} bgColor={bgColor} textColor={textColor} onClick={clickOnWrapper}>
            <Wrapper>
                {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
                {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
            </Wrapper>

            {image && (
                <BgWrapper>
                    <Bg src={image} alt={title} objectFit="contain" width={160} height={166} quality={95} />
                </BgWrapper>
            )}
            {angles?.map((position, index) => (
                <>
                    <CardAngle key={index} position={position}>
                        <Angle />
                    </CardAngle>
                </>
            ))}
        </Container>
    );

    return (
        <>
            {href ? (
                <Link href={href} passHref prefetch={false}>
                    <a target={href.startsWith('http') ? '_blank' : '_self'}>{Card}</a>
                </Link>
            ) : modalId ? (
                <ModalBtn id={modalId} as="div">
                    {Card}
                </ModalBtn>
            ) : (
                <>{Card}</>
            )}
        </>
    );
};

export default Card2;
