import styled from 'styled-components';
import { adaptiveHardValues, font, mediaBreakpointUp, offset } from '@/style/mixins';
import { Container as PrimaryCard } from '../../../components-new/blocks/PrimaryCard/PrimaryCard.styled';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<BaseOffsetsUI>`
    position: relative;
    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    padding-left: 20px;
    padding-right: 20px;

    ${mediaBreakpointUp('xl')} {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        ${font('h1')};
        ${offset('xl', 'margin-bottom')};
        ${adaptiveHardValues('max-width', 552, 542, null)};
    }
`;

export const CardsList = styled.div`
    padding: 0;
    list-style-type: none;
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    ${mediaBreakpointUp('md')} {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }

    ${PrimaryCard} {
        &:nth-child(2) {
            order: -1;
            grid-column: span 2;

            ${mediaBreakpointUp('md')} {
                grid-column: span 1;
                order: 0;
            }
        }
    }
`;
