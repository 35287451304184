import React, { useEffect, useState } from 'react';
import { Container, IconsContainer, SpoilerBody, SpoilerHead, SpoilerHeadBtn, SpoilerHeadText } from './Spoiler.styled';
import { BaseUI } from '@/interfaces';
import Arrow from '@/icons/arrow.svg';
import SpoilerMenuIcon from '@/icons/new/spoiler-menu.svg';
import SpoilerMenuCloseIcon from '@/icons/new/close.svg';

interface SpoilerProps extends BaseUI {
    header: React.ReactNode;
    headerBtn?: boolean;
    headText?: string;
    className?: string;
    arrowBtn?: boolean;
    menuBtn?: boolean;
    plusIcon?: boolean;
    clickHandler?: () => void;
    activeIndex?: boolean;
    outsideIsOpen?: boolean;
    onClickFunc?: React.Dispatch<React.SetStateAction<null | string>>;
    id?: string;
    metrika?: React.MouseEventHandler;
}

const Spoiler: React.FC<SpoilerProps> = ({
    header,
    headText,
    children,
    className,
    arrowBtn,
    menuBtn,
    plusIcon,
    clickHandler,
    activeIndex = false,
    outsideIsOpen,
    onClickFunc,
    id,
    metrika
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (outsideIsOpen !== undefined) setIsOpen(outsideIsOpen);
    }, [outsideIsOpen]);

    useEffect(() => {
        setIsOpen(activeIndex);
    }, [activeIndex]);

    return (
        <Container className={`${className ? className : ''} ${isOpen ? '_is-open' : ''}`}>
            <SpoilerHead
                menuIcon={menuBtn}
                className={isOpen ? '_open' : ''}
                onClick={(e) => {
                    clickHandler ? clickHandler() : setIsOpen(!isOpen);
                    if (onClickFunc && id) onClickFunc(id);
                    if (!isOpen && metrika) metrika(e);
                }}
            >
                {header}
                <SpoilerHeadBtn menuIcon={menuBtn} arrowIcon={arrowBtn} plusIcon={plusIcon} isOpen={isOpen}>
                    {headText && <SpoilerHeadText dangerouslySetInnerHTML={{ __html: headText }} />}
                    {arrowBtn && <Arrow />}
                    {menuBtn && (
                        <IconsContainer>
                            {isOpen ? <SpoilerMenuCloseIcon className="icon_closed" /> : <SpoilerMenuIcon className="icon_menu" />}
                        </IconsContainer>
                    )}
                </SpoilerHeadBtn>
            </SpoilerHead>
            <SpoilerBody isOpen={isOpen}>{children}</SpoilerBody>
        </Container>
    );
};

export default Spoiler;
