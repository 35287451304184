import styled from 'styled-components';
import { color, font, mediaBreakpointUp } from '@/style/mixins';
import { Colors } from '@/style/colors';

export interface TagContainerProps {
    big?: boolean;
    gray?: boolean;
    black?: boolean;
    backgroundColor?: Colors;
    bgOpacity?: number;
    textColor?: Colors;
    isRegularFont?: boolean;
}

export const Container = styled.div<TagContainerProps>`
    position: relative;
    border-radius: 6px;
    ${({ backgroundColor, bgOpacity }) =>
        `background-color:${color(backgroundColor ? backgroundColor : 'ui-bg', bgOpacity ? bgOpacity : 1)}`};
    ${({ textColor }) => textColor && `color:${color(textColor)}`};
    ${font('text3-sb')};
    ${({ isRegularFont }) => isRegularFont && `font-weight: 400;`};

    ${({ big }) =>
        big
            ? `
                padding: 5px 10px 6px;
                border: 2px solid transparent;
                
                ${mediaBreakpointUp('xl')} {
                    padding: 7px 8px 9px;
                    
                    &:focus {
                        border-color: ${color('blue3')};
                    }
                    
                    &:active {
                        background: ${color('blue')};
                    }
                    &:hover {
                        color: ${color('black', 0.5)};
                    }
                }
                ${mediaBreakpointUp('fhd')} {
                    padding: 7px 12px 9px;
                }
            `
            : `
                padding: 8px 12px;
            `};
`;
