import styled from 'styled-components';
import { color, font, mediaBreakpointUp, hover } from '@/style/mixins';
import Image from '@/components/common/Image/Image';
import { Colors } from '@/style/colors';

export const Bg = styled(Image)`
    width: 130px;
    height: 135px;

    ${mediaBreakpointUp('xl')} {
        width: 160px;
        height: 166px;
    }

    span {
        transition: transform 0.3s ease-in-out;
    }
`;

export const CardAngle = styled.div<{ position: string }>`
    display: none;

    ${mediaBreakpointUp('xl')} {
        display: block;
        position: absolute;
        overflow: hidden;
        z-index: 0;
        ${({ position }) => {
            switch (position) {
                case 'bottom-right':
                    return `
                        bottom: 0;
                        right: 0;
                        transform: rotate(-90deg);
                    `;
                case 'top-left':
                    return `
                        top: -1px;
                        left: -1px;
                        transform: rotate(90deg);
                    `;
                case 'top-right':
                    return `
                        top: -1px;
                        right: -1px;
                        transform: rotate(180deg);
                    `;
                case 'bottom-left':
                    return `
                        bottom: 0;
                        left: 0;
                    `;
                default:
                    return '';
            }
        }}
        width: 28px;
        height: 28px;

        svg {
            fill: ${color('Bg-Light')};
        }
    }
`;

export const Container = styled.div<{ bgColor?: Colors; textColor?: Colors }>`
    position: relative;
    padding: 20px;
    border-radius: 20px;
    color: ${({ textColor }) => color(textColor ? textColor : 'text-primary')};
    background-color: ${({ bgColor }) => color(bgColor ? bgColor : 'white')};
    backface-visibility: hidden;

    ${mediaBreakpointUp('xl')} {
        padding: 24px;
    }

    ${hover(`
        ${Bg} {
            span {
                transform: scale(1.1);
            }
        `)};
`;

export const Wrapper = styled.div`
    position: relative;
    width: 187px;
    user-select: none;

    ${mediaBreakpointUp('xl')} {
        width: 220px;
    }
`;

export const BgWrapper = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 130px;
    height: 135px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    ${mediaBreakpointUp('xl')} {
        display: flex;
        justify-content: flex-end;
        width: 176px;
        height: 166px;
    }
`;

export const Title = styled.p`
    position: relative;
    ${font('h3')};
`;

export const Text = styled.p`
    margin-top: 12px;
    ${font('text2-sb')};
`;
