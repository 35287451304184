import styled from 'styled-components';
import { font, mediaBreakpointUp, vw, offset, color, hover } from '@/style/mixins';
import { BasicBackgrounds } from '@/style/colors';
import { baseTheme } from '@/style/themes';

export const ListWrapper = styled.ul<{ columnsCount: 1 | 3; bgColor?: BasicBackgrounds }>`
    margin: 0;
    padding: 0;
    list-style: none;

    background-color: ${({ bgColor }) => color(bgColor ? bgColor : 'white')};

    ${mediaBreakpointUp('md')} {
        ${({ columnsCount }) => (columnsCount === 3 ? 'column-count: 3;' : '')}
        ${({ columnsCount }) => (columnsCount === 3 ? `column-gap: ${vw(16, 'md')};` : '')}
    }

    ${mediaBreakpointUp('xl')} {
        ${({ columnsCount }) => (columnsCount === 3 ? `column-gap: 16px;` : '')}
    }
`;

export const Item = styled.li`
    position: relative;
    ${font('text2-sb')}
    padding-left: 16px;

    &:not(:first-child) {
        ${offset('xxs', 'margin-top')};
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        background-color: currentColor;
        border-radius: 1px;
    }

    a {
        ${({ theme }) => (theme !== baseTheme ? `color: ${color(theme.main.background)}` : `color: ${color('link-accent-default')}`)};
        ${({ theme }) =>
            theme !== baseTheme ? hover(`color: ${color(theme.secondary.background)}; `) : hover(`color: ${color('link-accent-hover')};`)}
    }
`;
