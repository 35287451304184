import styled from 'styled-components';
import { mediaBreakpointUp } from '@/style/mixins';
import { Container as Card2, Wrapper } from '../../blocks/Cards/Card2/Card2.styled';

export const Container = styled.div`
    display: grid;
    gap: 12px;
    padding: 0 20px;

    ${Card2} {
        min-height: 140px;
    }

    ${mediaBreakpointUp('md')} {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        ${Card2} {
            min-height: 166px;
            padding: 24px;
        }
    }
    ${mediaBreakpointUp('xl')} {
        padding: 0;

        ${Card2} {
            ${Wrapper} {
                width: 100%;
            }
        }
    }
`;
