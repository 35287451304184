import React from 'react';
import { Container, CardsList } from './SupportSection.styled';
import PrimaryCard, { IPrimaryCard } from 'src/components-new/blocks/PrimaryCard/PrimaryCard';
import { BaseOffsetsUI, IMetrika } from '@/interfaces';
import { METRIKS_SAMPLES } from '@/metriks/metriksSample';
import { useRouter } from 'next/router';

export interface SupportSectionProps extends BaseOffsetsUI, IMetrika {
    title: string | '';
    cards: IPrimaryCard[] | [];
    metriksSampleSupport?: { events: string[]; category: string };
}

const METRIKA_ACTIONS = ['click-faq', 'click-rules', 'click-question'];

const SupportSection: React.FC<SupportSectionProps> = ({ title, cards, topOffset, bottomOffset, metriksSampleSupport }) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    const getPrimaryCardMetriks = (index: number) => {
        if (!METRIKS_SAMPLES[pathname]?.hasOwnProperty(`card${index}`)) return;

        return {
            event: METRIKS_SAMPLES[pathname][`card${index}`].event,
            category: METRIKS_SAMPLES[pathname][`card${index}`].category,
            action: METRIKA_ACTIONS[index]
        };
    };

    return (
        <>
            <Container topOffset={topOffset} bottomOffset={bottomOffset}>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <CardsList>
                    {cards?.map((card, i) => (
                        <PrimaryCard
                            key={i}
                            {...card}
                            metriksSample={
                                (metriksSampleSupport && {
                                    event: metriksSampleSupport.events[i],
                                    category: metriksSampleSupport.category,
                                    action: METRIKA_ACTIONS[i]
                                }) ||
                                getPrimaryCardMetriks(i) || {
                                    event: 'support-click-banner',
                                    category: 'support',
                                    action: 'click-banner'
                                }
                            }
                        />
                    ))}
                </CardsList>
            </Container>
        </>
    );
};

export default SupportSection;
